@use '@carbon/styles';
@use '@carbon/styles/scss/grid/flexbox';

@use './assets/scss/base/variables';

/*
Volgens de documentatie van Carbon, zouden deze 'theme tokens' instelbaar moeten zijn door het volgende te doen:
@use '@carbon/styles' with (
  $button-primary: ...
  ...
)

Dit werkt echter niet (bug in carbon-components-angular?). Volgens dit het issue https://github.com/carbon-design-system/carbon-components-angular/issues/2668
is het de bedoeling om de 'theme' mixin te importen onder de body. Dan worden ook de custom tokens toegepast.
*/
@include styles.add-component-tokens(
  $token-map: (
    button-primary: variables.$ech-orange,
    layer-01: white,
    text-on-color: black,
    icon-primary: variables.$ech-icon,
    focus: variables.$ech-focus,
    button-primary-hover: variables.$ech-hover-primary,
    button-primary-active: variables.$ech-active-primary,
    button-secondary: variables.$ech-light-gray,
    button-secondary-hover: variables.$ech-gray,
    support-success: variables.$ech-focus,
    border-interactive: variables.$ech-orange
  )
);

.cds--select-input, .cds--text-input {
  &:disabled {
    border-block-end-color: variables.$ech-gray;
  }
}

body {
  @include styles.theme(styles.$white);
}

@import './assets/fonts/Noto_Serif/noto_serif.css';
@import './assets/fonts/Roboto/roboto.css';
@import 'carbon-override';
