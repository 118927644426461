@font-face {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 400;
    src: url(Noto+Serif_400_normal.eot);
    src: local('☺'), url(Noto+Serif_400_normal.eot?#iefix) format('embedded-opentype'), url(Noto+Serif_400_normal.woff2) format('woff2'),
    url(Noto+Serif_400_normal.woff) format('woff'), url(Noto+Serif_400_normal.ttf) format('truetype'),
    url(Noto+Serif_400_normal.svg#Noto+Serif_400_normal) format('svg');
}

@font-face {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 700;
    src: url(Noto+Serif_700_normal.eot);
    src: local('☺'), url(Noto+Serif_700_normal.eot?#iefix) format('embedded-opentype'), url(Noto+Serif_700_normal.woff2) format('woff2'),
    url(Noto+Serif_700_normal.woff) format('woff'), url(Noto+Serif_700_normal.ttf) format('truetype'),
    url(Noto+Serif_700_normal.svg#Noto+Serif_700_normal) format('svg');
}

@font-face {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 400;
    src: url(Noto+Serif_400_italic.eot);
    src: local('☺'), url(Noto+Serif_400_italic.eot?#iefix) format('embedded-opentype'), url(Noto+Serif_400_italic.woff2) format('woff2'),
    url(Noto+Serif_400_italic.woff) format('woff'), url(Noto+Serif_400_italic.ttf) format('truetype'),
    url(Noto+Serif_400_italic.svg#Noto+Serif_400_italic) format('svg');
}

@font-face {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 700;
    src: url(Noto+Serif_700_italic.eot);
    src: local('☺'), url(Noto+Serif_700_italic.eot?#iefix) format('embedded-opentype'), url(Noto+Serif_700_italic.woff2) format('woff2'),
    url(Noto+Serif_700_italic.woff) format('woff'), url(Noto+Serif_700_italic.ttf) format('truetype'),
    url(Noto+Serif_700_italic.svg#Noto+Serif_700_italic) format('svg');
}
