@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url(Roboto_100_normal.eot);
    src: local('☺'), url(Roboto_100_normal.eot?#iefix) format('embedded-opentype'), url(Roboto_100_normal.woff2) format('woff2'),
    url(Roboto_100_normal.woff) format('woff'), url(Roboto_100_normal.ttf) format('truetype'),
    url(Roboto_100_normal.svg#Roboto_100_normal) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(Roboto_300_normal.eot);
    src: local('☺'), url(Roboto_300_normal.eot?#iefix) format('embedded-opentype'), url(Roboto_300_normal.woff2) format('woff2'),
    url(Roboto_300_normal.woff) format('woff'), url(Roboto_300_normal.ttf) format('truetype'),
    url(Roboto_300_normal.svg#Roboto_300_normal) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(Roboto_400_normal.eot);
    src: local('☺'), url(Roboto_400_normal.eot?#iefix) format('embedded-opentype'), url(Roboto_400_normal.woff2) format('woff2'),
    url(Roboto_400_normal.woff) format('woff'), url(Roboto_400_normal.ttf) format('truetype'),
    url(Roboto_400_normal.svg#Roboto_400_normal) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(Roboto_500_normal.eot);
    src: local('☺'), url(Roboto_500_normal.eot?#iefix) format('embedded-opentype'), url(Roboto_500_normal.woff2) format('woff2'),
    url(Roboto_500_normal.woff) format('woff'), url(Roboto_500_normal.ttf) format('truetype'),
    url(Roboto_500_normal.svg#Roboto_500_normal) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(Roboto_700_normal.eot);
    src: local('☺'), url(Roboto_700_normal.eot?#iefix) format('embedded-opentype'), url(Roboto_700_normal.woff2) format('woff2'),
    url(Roboto_700_normal.woff) format('woff'), url(Roboto_700_normal.ttf) format('truetype'),
    url(Roboto_700_normal.svg#Roboto_700_normal) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url(Roboto_900_normal.eot);
    src: local('☺'), url(Roboto_900_normal.eot?#iefix) format('embedded-opentype'), url(Roboto_900_normal.woff2) format('woff2'),
    url(Roboto_900_normal.woff) format('woff'), url(Roboto_900_normal.ttf) format('truetype'),
    url(Roboto_900_normal.svg#Roboto_900_normal) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url(Roboto_100_italic.eot);
    src: local('☺'), url(Roboto_100_italic.eot?#iefix) format('embedded-opentype'), url(Roboto_100_italic.woff2) format('woff2'),
    url(Roboto_100_italic.woff) format('woff'), url(Roboto_100_italic.ttf) format('truetype'),
    url(Roboto_100_italic.svg#Roboto_100_italic) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url(Roboto_300_italic.eot);
    src: local('☺'), url(Roboto_300_italic.eot?#iefix) format('embedded-opentype'), url(Roboto_300_italic.woff2) format('woff2'),
    url(Roboto_300_italic.woff) format('woff'), url(Roboto_300_italic.ttf) format('truetype'),
    url(Roboto_300_italic.svg#Roboto_300_italic) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url(Roboto_400_italic.eot);
    src: local('☺'), url(Roboto_400_italic.eot?#iefix) format('embedded-opentype'), url(Roboto_400_italic.woff2) format('woff2'),
    url(Roboto_400_italic.woff) format('woff'), url(Roboto_400_italic.ttf) format('truetype'),
    url(Roboto_400_italic.svg#Roboto_400_italic) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url(Roboto_500_italic.eot);
    src: local('☺'), url(Roboto_500_italic.eot?#iefix) format('embedded-opentype'), url(Roboto_500_italic.woff2) format('woff2'),
    url(Roboto_500_italic.woff) format('woff'), url(Roboto_500_italic.ttf) format('truetype'),
    url(Roboto_500_italic.svg#Roboto_500_italic) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url(Roboto_700_italic.eot);
    src: local('☺'), url(Roboto_700_italic.eot?#iefix) format('embedded-opentype'), url(Roboto_700_italic.woff2) format('woff2'),
    url(Roboto_700_italic.woff) format('woff'), url(Roboto_700_italic.ttf) format('truetype'),
    url(Roboto_700_italic.svg#Roboto_700_italic) format('svg');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url(Roboto_900_italic.eot);
    src: local('☺'), url(Roboto_900_italic.eot?#iefix) format('embedded-opentype'), url(Roboto_900_italic.woff2) format('woff2'),
    url(Roboto_900_italic.woff) format('woff'), url(Roboto_900_italic.ttf) format('truetype'),
    url(Roboto_900_italic.svg#Roboto_900_italic) format('svg');
}
