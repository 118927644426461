// Huisstijl

// colors:
$ech-red: #da1e28;
$ech-orange: #fedb97;
$ech-focus: #f0b73d;
$ech-hover-primary: #fdc86d;
$ech-active-primary: #f4b11f;
$ech-icon: #ffaa00;
$ech-hover-row: #faf2df;
$ech-light-gray: #f4f4f6;
$ech-gray: #e0e0e0;
$ech-dark-gray: #304050;
$ech-black: #212121;
$ech-green: #017e01;
$ech-blue: #0986f1;
$ech-card-shadow: rgba(0, 0, 0, 0.12);
