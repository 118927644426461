@use '@carbon/styles/scss/spacing';
@import 'base/global';

body {
  font-size: 0.875rem;
}

.cds--btn {
  border-radius: 4px;
  padding-left: 40px;
  padding-right: 40px;
}

.cds--btn--field {
  padding: calc(0.675rem - 3px) 16px calc(0.675rem - 3px) 16px;
}

.cds--btn--primary {
  font-family: 'Noto Serif', serif;
  font-weight: bold;
}

.cds--select-input,
.cds--text-input,
.cds--dropdown {
  border-radius: 4px;
}

.cds--text-input,
.cds--text-area {
  border: 1px solid $ech-gray;
}

.cds--select-input {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: $ech-gray;
}

.cds--dropdown {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: $ech-gray;
}

.cds--text-area {
  width: 100%;
}

.cds--radio-button-wrapper {
  margin-top: 0.6rem;
}

.modal-fixed-top .cds--modal-container {
  position: fixed;
  top: 10vh;
  max-height: 80vh;
}

.cds--modal-close__icon {
  fill: $ech-black;
}

.cds--modal-close-button {
  position: fixed;
}

cds-modal-header span[role=tooltip] {
  display: none;
}

.cds--modal-close {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: unset;
}

.cds--modal-content {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.cds--modal-content--overflow-indicator {
  bottom: 0;
  display: none;
}

.cds--file__state-container .cds--file-close {
  fill: $ech-black;
}

tbody tr:hover td {
  background-color: $ech-hover-row !important;
}

thead tr th {
  background-color: $ech-light-gray !important;
}

.cds--data-table-header {
  padding-left: 0;
  padding-top: 0;
}

.cds--pagination {
  border-top: 0;
  border-bottom: 1px solid $ech-gray;
}

.cds--pagination .cds--select-input {
  border-radius: 0;
  border-width: 0;
}

.cds--tag--teal {
  background-color: $ech-orange !important;
  color: black;
  font-family: Roboto, sans-serif;
}

.cds--popover__trigger.cds--popover--bottom.cds--popover--align-start .cds--assistive-text {
  max-width: 300px;
}

.cds--header__submenu {
  list-style-type: none;
}

@for $index from 1 through 6 {
  h#{$index} {
    font-family: 'Noto Serif', serif;
  }
}

.cds--tabs {
  .cds--tabs__nav-link {
    padding: 0 spacing.$spacing-06;
  }

  margin-left: auto;
  margin-right: auto;
  max-width: 88rem;
}

.cds--overflow-menu-options {
  background-color: white;
}

.servicedesk-wrapper {
  display: block;
  width: 100%;
  max-width: 288px;
}

.servicedesk-wrapper:only-child a {
  text-decoration: none;
  color: black;
}

.servicedesk-wrapper .cds--overflow-menu-options__btn {
  max-width: none;
  color: black;
}

.servicedesk-wrapper .cds--overflow-menu-options__btn:hover svg {
  fill: black;
}

.servicedesk-wrapper .cds--overflow-menu.cds--overflow-menu--open:hover {
  background-color: $ech-hover-primary;
}

.servicedesk-wrapper .cds--overflow-menu:hover,
.cds--overflow-menu__trigger:hover {
  background-color: $ech-hover-primary;
}

.servicedesk-wrapper .bank-info {
  color: $ech-active-primary !important;
}

.cds--file__state-container .cds--file-complete {
  fill: $ech-green;
}

.cds--accordion__item {
  background-color: $ech-light-gray !important;
  margin-bottom: spacing.$spacing-03;
  border: none;
}

p.cds--accordion__title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cds--accordion__content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cds--accordion__heading[disabled] {
  color: black;
}

.cds--accordion__heading[disabled] .cds--accordion__arrow {
  display: none;
}

.extra-button {
  width: auto !important;
}

.extra-button .cds--overflow-menu-options__btn {
  max-width: 20rem !important;
}

.cds--btn--tertiary {
  border: none;
  padding: spacing.$spacing-05 !important;
}

.cds--btn--danger {
  color: white;
}

.cds--list-box__field:has(.cds--list-box__menu-icon) .cds--list-box__selection::after {
  display: none;
}
