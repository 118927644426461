@use '@carbon/styles/scss/spacing';
@import './variables';

body * {
  font-family: 'Roboto', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.dt--fixed {
  table-layout: fixed;
  width: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.cds--content {
  padding-top: spacing.$spacing-12;
  padding-bottom: spacing.$spacing-10;
}

.modal-footer-button {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: spacing.$spacing-06;
  white-space: nowrap;
}

.heading--1 {
  font-family: 'Noto Serif', serif;
  font-size: 24px;
  letter-spacing: 0.15px;
  font-weight: bold;
}

.heading--2 {
  font-family: 'Noto Serif', serif;
  font-size: 20px;
  letter-spacing: 0.15px;
  font-weight: normal;
  margin-bottom: spacing.$spacing-05;
}

.heading--3 {
  font-family: 'Noto Serif', serif;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: bold;
  line-height: 21px;
}

.error-font {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  display: block;
  color: $ech-red;
}

.row-margin {
  margin-bottom: spacing.$spacing-05;
}

.no-javascript {
  margin: 20px;
}

.float-right {
  float: right;
}

.cf::after {
  content: '';
  clear: both;
  display: table;
}

.gegevens-label {
  color: $ech-dark-gray;
}

.gegevens-row {
  margin-bottom: spacing.$spacing-02;
}

.gegevens-header {
  font-weight: 600;
}

.gegevens-sectie {
  margin-top: spacing.$spacing-05;
}

.sectie-margin {
  display: block;
  margin-bottom: spacing.$spacing-10;
}

.card {
  padding: spacing.$spacing-07;
  margin-bottom: spacing.$spacing-10;
  background: white;
  box-shadow: 0 2px 3px 0 $ech-card-shadow;
}

.actie-margin {
  margin-right: spacing.$spacing-04;
}

.center-align {
  margin-left: auto;
  margin-right: auto;
  max-width: 88rem;
}

.secondary-background {
  background-color: $ech-light-gray !important;
  padding-top: spacing.$spacing-07;
  min-height: 61rem;
}

.hyperlink {
  color: $ech-active-primary !important;
  text-decoration: none;
  cursor: pointer;
}

.hyperlink:hover {
  color: $ech-hover-primary !important;
  cursor: pointer;
}

.table-acties {
  text-align: right;
}

.sticky-buttons {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: spacing.$spacing-03 0;
  z-index: 2;
}
